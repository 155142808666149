.member {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    // background
    .memwrapper {
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: space-around;
        align-items: center;
        margin-top: 25px;
        border: 3px solid #2c6ff4;
        border-radius: 10px;
        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }

        .price {
            font-size: 2rem;
            font-weight: 700;
            color: rgb(0, 0, 0);
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            line-height: 0.9;
            align-items: center;
            .og {
                // strikethrough
                text-decoration: line-through;
                color: rgb(117, 117, 117);
                font-size: 1.5rem;
            }
        }
        
        .features {
            .feature {
                display: flex;
                
                .icon {
                    // font-size: 1.5rem;
                    display: inline;
                    margin-right: 10px;
                    color: black;
                }
                
                .text1 {
                    display: inline;
                    font-weight: 700;
                    color: rgb(0, 0, 0);
                    margin-bottom: 10px;
                    h3 {
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .code {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            gap: 10px;
            .codeInput {
                width: 200px;
                padding: 5px;
                background-color: rgb(255, 255, 255);
                
                border: #f19370 3px solid;
            }
            .codeButton {
                width: 50px;
                padding: 0;
                background-color: rgb(255, 255, 255);
                
                border: #f19370 3px solid;
            }
        }
        .buttonWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            
            .button {
                width: 200px;
                padding: 0;
                background-color: rgb(255, 255, 255);
                
                border: #f19370 3px solid;
            }
        }
    }
}
.hideit {
    display: none !important;
}