@import '../../global.scss';

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
}



/*===========================
    1. COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #505056;
  overflow-x: hidden;

  @font-face {
    font-family: 'Hey Comic';
    src: url('http://reeprofile.com/HeyComic.woff2') format('woff2'),
      url('http://reeprofile.com/HeyComic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Hey Comic';
    src: url('http://reeprofile.com/HeyComic.woff2') format('woff2'),
      url('http://reeprofile.com/HeyComic.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

}

/* ==========================================vue */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
  transition: all linear 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #0e1133;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #505056;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.slick-slide {
  outline: 0;
}

/*===== All Button Style =====*/
.main-btn {
  // display: inline-block;
  position: relative;
  right: 10px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #2b70fa;
  padding: 0 10px;
  font-size: 15px;
  line-height: 45px;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #2b70fa;
  // margin-right: 10rem;
}

.main-btn:hover {
  background-color: #fff;
  color: #2b70fa;
  border-color: #2b70fa;
}

.main-btn.main-btn-2 {
  background-color: #fff;
  color: #2b70fa;
  border-color: #2b70fa;
}

.main-btn.main-btn-2:hover {
  background-color: #2b70fa;
  border-color: #2b70fa;
  color: #fff;
}

.section-title {
  padding: 15px 0px;

  @include mobile {
    padding-bottom: 15px;
  }
}

.section-title {
  .title {
    font-size: 44px;
    line-height: 54px;
    color: #fbf8be;
  }

  .subhead {
    font-size: 18px;
    line-height: 20px;
    color: #fbf8be;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .section-title .title {
    font-size: 26px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.section-title p {
  font-size: 18px;
  line-height: 28px;
  margin-top: 11px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .section-title p {
    font-size: 15px;
  }
}

.section-title .main-btn {
  border-radius: 30px;
  border: 2px solid #e7eaef;
  background: #fff;
  color: #0e1133;
}

.section-title .main-btn:hover {
  background: #ff3e66;
  color: #fff;
  border-color: #ff3e66;
}

.section-title.section-title-2 .title {
  color: #fff;
}

.section-title.section-title-2 p {
  color: #fff;
}

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  display: none;
}

.back-to-top a {
  height: 40px;
  // width: 40px;
  text-align: center;
  line-height: 40px;
  // background: #2b70fa;
  border-radius: 6px;
  color: #fff;
}

.back-to-top.back-to-top-2 a {
  background: #ff3e66;
}

.back-to-top.back-to-top-3 a {
  background: #ff6b58;
}

.back-to-top.back-to-top-6 a {
  background: #9b2cfa;
}

.back-to-top.back-to-top-5 a {
  background: #801f82;
}

.back-to-top.back-to-top-8 a {
  background: #db0f30;
}

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
  transition: all linear 0.3s;
}

.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.offcanvas_menu {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .offcanvas_menu {
    display: block;
  }
}

.offcanvas_menu_wrapper {
  width: 290px;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 0;
  height: 100%;
  transition: 0.5s;
  left: 0;
  margin-left: -300px;
  padding: 50px 15px 30px;
  overflow-y: visible;
}

.offcanvas_menu_wrapper.active {
  margin-left: 0;
}

.offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}

.offcanvas_menu_wrapper .header-btn {
  margin-bottom: 30px;
}

.offcanvas_menu_wrapper .header-btn a {
  color: #222;
}

.offcanvas_menu_wrapper .header-btn a:hover {
  color: #222;
}

.offcanvas_main_menu li {
  position: relative;
}

.offcanvas_main_menu li:last-child {
  margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}

.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas_main_menu li a:hover {
  color: #2b70fa;
}

.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.offcanvas_footer {
  padding-bottom: 50px;
  text-align: center;
}

.offcanvas_footer span a {
  font-size: 14px;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas_footer span a:hover {
  color: #2b70fa;
}

.offcanvas_menu_wrapper.active .canvas_close {
  opacity: 1;
  visibility: visible;
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: -50px;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
}

.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
  border: 1px solid #2b70fa;
  border-radius: 10px;
  background: #2b70fa;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.canvas_close a:hover {
  background: #2b70fa;
  border-color: #2b70fa;
  color: #fff;
}

.canvas_open a {
  font-size: 26px;
  width: 50px;
  height: 42px;
  display: block;
  line-height: 39px;
  text-align: center;
  border: 1px solid #232323;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.canvas_open a:hover {
  color: #2b70fa;
  border-color: #2b70fa;
}

.offcanvas-social {
  margin-bottom: 40px;
  margin-top: 40px;
}

.offcanvas-social ul li {
  display: inline-block;
}

.offcanvas-social ul li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  color: #2b70fa;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas-social ul li a:hover {
  background: #2b70fa;
  border-color: #2b70fa;
  color: #fff;
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #0e1133;
}



.preloader-close {
  position: fixed;
  z-index: 999999;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  right: 40px;
  bottom: 40px;
  font-weight: 600;
  line-height: 60px;
  background: #2b70fa;
  padding: 0 15px;
  border-radius: 5px;
}

.loader-wrap {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.loader-wrap .layer-one {
  position: absolute;
  left: 0%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-two {
  position: absolute;
  left: 33.3333%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-three {
  position: absolute;
  left: 66.6666%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f4f9ff;
}

/*===========================
    SEARCH css 
===========================*/
.search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

.search-box .search-header .search-title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.search-box .search-header .search-close button {
  background: none;
  border: 0;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-right: 35px;
  position: relative;
}

.search-box .search-header .search-close button span {
  width: 21px;
  height: 2px;
  background-color: #fff;
  display: block;
  position: absolute;
  right: 0;
}

.search-box .search-header .search-close button span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.search-box .search-header .search-close button span:nth-child(2) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 13px;
}

.search-box .search-body {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.search-box .search-body .search-form {
  position: relative;
}

.search-box .search-body .search-form input {
  width: 100%;
  border: 0;
  height: 80px;
  padding-left: 30px;
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 20px;
  color: #707070;
}

.search-box .search-body .search-form input::placeholder {
  color: #707070;
  opacity: 1;
}

.search-box .search-body .search-form button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  font-size: 36px;
  color: #707070;
  background: none;
  border: 0;
}

.search-box .search-footer {
  padding-bottom: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.search-box .search-footer .search-footer-content h4 {
  color: #707070;
  font-size: 24px;
}

.search-box .search-footer .search-footer-content p {
  color: #222;
  font-size: 16px;
}

.search-box.open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

/*===========================
    2.HEADER css 
===========================*/
.sticky.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1010;
  background: #fff;
  box-shadow: 0 15px 30px 0 #0000000f;
  padding-top: 20px;
  padding-bottom: 20px;
  animation: sticky 1.2s;
}

.header-area {
  padding: 20px 0px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
}

.header-area .header-2-area .btn-box .main-btn {
  background: #ff3e66;
  border-radius: 30px;
  border-color: #ff3e66;
}

.header-area .header-2-area .btn-box .main-btn:hover {
  background: transparent;
  color: #ff3e66;
}

.header-area .header-3-area .sticky.sticky {
  background: #0e1133;
}

.header-area .header-4-area .sticky.sticky {
  background: linear-gradient(90deg, #6b1fcd 0%, #374ede 100%);
}

.header-area .header-page-area .sticky.sticky {
  background: #0a44b9;
}

.header-area .header-7-area .sticky.sticky {
  background: #290645;
}

.header-area .header-error-page {
  box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-nav-box .btn-box {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav-box .btn-box {
    padding-right: 50px;
  }
}

@media (max-width: 767px) {
  .header-nav-box .btn-box {
    padding-right: 30px;
  }
}

.header-nav-box .btn-box .login-btn {
  color: #0e1133;
  font-size: 15px;
  font-weight: 500;
}

.header-nav-box .btn-box .login-btn i {
  padding-right: 6px;
}

// @media (max-width: 767px) {
//   .header-nav-box .btn-box .main-btn {
//     display: none;
//   }
// }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-nav-box .btn-box .main-btn {
    padding: 0 10px;
    display: inline-block;
  }
}

.header-nav-box.header-nav-box-3 .header-main-menu ul>li>a {
  color: #fff;
}

.header-nav-box.header-nav-box-3 .header-main-menu ul>li .sub-menu li a {
  color: #0e1133;
}

.header-nav-box.header-nav-box-3 .btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-3 .btn-box .main-btn {
  background: #ff6b58;
  border-color: #ff6b58;
}

.header-nav-box.header-nav-box-3 .btn-box .main-btn:hover {
  background: transparent;
  color: #ff6b58;
}

.header-nav-box.header-nav-box-3 .toggle-btn {
  color: #fff;
  font-size: 20px;
}

.header-nav-box.header-nav-box-3.header-nav-box-inner-page .btn-box .main-btn {
  border-color: #fff;
  background: transparent;
}

.header-nav-box.header-nav-box-3.header-nav-box-inner-page .btn-box .main-btn:hover {
  background: #fff;
  color: #2b70fa;
}

.header-nav-box.header-nav-box-5 .header-main-menu ul>li>a {
  color: #fff;
}

.header-nav-box.header-nav-box-5 .header-main-menu ul>li .sub-menu li a {
  color: #0e1133;
}

.header-nav-box.header-nav-box-5 .btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-5 .btn-box .main-btn {
  background: #f84a6e;
  border-color: #f84a6e;
}

.header-nav-box.header-nav-box-5 .btn-box .main-btn:hover {
  background: transparent;
  color: #f84a6e;
}

.header-nav-box.header-nav-box-5 .toggle-btn {
  color: #fff;
  font-size: 20px;
}

.header-nav-box.header-nav-4-box .header-main-menu ul>li>a:hover {
  color: #801f82;
}

.header-nav-box.header-nav-4-box .btn-box .main-btn {
  background: #801f82;
  border-color: #801f82;
}

.header-nav-box.header-nav-4-box .btn-box .main-btn:hover {
  background: transparent;
  color: #801f82;
}

@media (max-width: 767px) {
  .header-nav-box.header-nav-4-box .btn-box ul {
    margin-right: 10px;
  }
}

.header-nav-box.header-nav-4-box .btn-box ul li {
  display: inline-block;
}

.header-nav-box.header-nav-4-box .btn-box ul li a {
  font-size: 18px;
  color: #0e1133;
  margin-left: 15px;
}

.header-nav-box.header-nav-4-box .btn-box ul li a.cart-btn {
  position: relative;
}

.header-nav-box.header-nav-4-box .btn-box ul li a.cart-btn span {
  position: absolute;
  top: -7px;
  display: inline-block;
  right: -12px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 11px;
  background: #801f82;
  border-radius: 50%;
}

.header-nav-box.header-nav-box-6 .main-btn {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .main-btn:hover {
  background: transparent;
  color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .header-main-menu ul>li>a:hover {
  color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .btn-box form {
  display: inline-block;
  margin-left: 18px;
}

@media (max-width: 767px) {
  .header-nav-box.header-nav-box-6 .btn-box form {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-nav-box.header-nav-box-6 .btn-box form {
    display: inline-block;
  }
}

.header-nav-box.header-nav-box-6 .btn-box form .input-box {
  position: relative;
}

.header-nav-box.header-nav-box-6 .btn-box form .input-box i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.header-nav-box.header-nav-box-6 .btn-box form .input-box input {
  width: 105px;
  padding-left: 50px;
  border: 0;
  border-left: 1px solid #ddddea;
  background: transparent;
}

.header-nav-box.header-nav-box-7 .header-main-menu ul>li>a {
  color: #fff;
}

.header-nav-box.header-nav-box-7 .header-main-menu ul>li>a:hover {
  color: #2b70fa;
}

.header-nav-box.header-nav-box-7 .header-main-menu ul>li .sub-menu li a {
  color: #505056;
}

.header-nav-box.header-nav-box-7 .header-main-menu ul>li .sub-menu li a:hover {
  color: #2b70fa;
}

.header-nav-box.header-nav-box-7 .btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-7 .btn-box .toggle-btn {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-main-menu {
    display: none;
  }
}

.header-main-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-main-menu ul>li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-main-menu ul>li {
    margin-right: 14px;
  }
}

.header-main-menu ul>li>a {
  font-size: 15px;
  font-weight: 500;
  color: #0e1133;
  line-height: 45px;
}

.header-main-menu ul>li>a i {
  padding-left: 6px;
}

.header-main-menu ul>li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 220px;
  max-width: 220px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header-main-menu ul>li .sub-menu {
    min-width: 200px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-main-menu ul>li .sub-menu {
    min-width: 200px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main-menu ul>li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .header-main-menu ul>li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}

.header-main-menu ul>li .sub-menu>li {
  position: relative;
  margin-left: 0;
  display: block;
}

.header-main-menu ul>li .sub-menu>li .sub-menu {
  margin-left: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main-menu ul>li .sub-menu>li .sub-menu {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .header-main-menu ul>li .sub-menu>li .sub-menu {
    margin-left: 0;
  }
}

.header-main-menu ul>li .sub-menu>li .sub-nav-toggler {
  color: #0e1133;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.header-main-menu ul>li .sub-menu>li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: #505056;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header-main-menu ul>li .sub-menu>li a {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-main-menu ul>li .sub-menu>li a {
    padding: 0 20px;
  }
}

.header-main-menu ul>li .sub-menu>li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main-menu ul>li .sub-menu>li a i {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-main-menu ul>li .sub-menu>li a i {
    display: none;
  }
}

.header-main-menu ul>li .sub-menu>li a .sub-nav-toggler i {
  display: inline-block;
}

.header-main-menu ul>li .sub-menu>li a:hover {
  padding-left: 35px;
}

.header-main-menu ul>li .sub-menu>li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-main-menu ul>li .sub-menu>li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}

@media (max-width: 767px) {
  .header-main-menu ul>li .sub-menu>li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}

.header-main-menu ul>li .sub-menu>li .sub-menu li {
  position: relative;
}

.header-main-menu ul>li .sub-menu>li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.header-main-menu ul>li .sub-menu>li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}

.header-main-menu ul>li .sub-menu>li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}

.header-main-menu ul>li .sub-menu>li:hover .sub-nav-toggler {
  color: #2b70fa;
}

.header-main-menu ul>li .sub-menu>li:hover>a {
  color: #2b70fa;
}

.header-main-menu ul>li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

.header-8-area .header-nav-box .btn-box .main-btn {
  background: #db0f30;
  border-color: #db0f30;
}

.header-8-area .header-nav-box .btn-box .main-btn:hover {
  background: #fff;
  color: #db0f30;
}

.header-8-area .header-nav-box .header-main-menu ul>li a:hover {
  color: #db0f30;
}

/*===========================
    3.APPIE HERO css 
===========================*/
.hero-area {
  padding-top: 200px;
  min-height: 100vh;
  padding-bottom: 110px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  background: linear-gradient(130deg, rgba(255, 216, 233, 1) 0%, rgba(225, 250, 255, 1) 100%);
  /* background-color: #000; */
  // background-size: 800% 800%;
  animation: gradient 10000ms ease infinite;

  @include mobile {
    padding-top: 100px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  10% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  90% {
    background-position: 0% 50%;
  }
}

.con1 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;

  @include mobile {
    align-items: center;
  }
}

.con1 h3 {
  display: flex;
  flex-direction: column;

  @include mobile {
    position: relative;
    right: 2rem;
  }

  .welc {
    // background: linear-gradient(227deg, #13cfb8 0%, #462775 70%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    background-color: #6b0f1a;
    background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);

    font-size: 3rem;
    // display: none;
    position: absolute;
    left: 400px;
    width: 600px;
    top: -100px;
    // color: #13cfb8;
    font-family: "Hey comic";
  }
}

.con2 {
  display: flex;
  padding-left: 0.5rem;
  max-width: 200px;
  // transform: translatex(-20px);
}

.q,
.w,
.a,
.c,
.k {
  font-family: "Hey Comic";
  flex: 0 0 40px;
  min-width: 50px;
  border-radius: 0 50%;
  color: rgb(255, 255, 255);
  padding: 0.1rem;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.q {
  background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
  ;
}

.w {
  position: relative;
  background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
  ;
  right: 5px;
}

.a {
  position: relative;
  background: linear-gradient(227deg, #F50000 0%, #FFD521 100%);
  right: 10px;
}

.c {
  position: relative;
  background: linear-gradient(to left, #da4453, #89216b);
  right: 15px;
}

.k {
  position: relative;
  background: linear-gradient(227deg, #13cfb8 0%, #462775 70%);
  right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 36px;
    line-height: 46px;
    padding: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 46px;
    line-height: 56px;
    padding: 0;
  }
}

.hero-area.hero-3-area .hero-content p {
  color: #fff;
  opacity: 0.5;
  font-size: 18px;
  line-height: 28px;
}

.hero-area.hero-3-area .hero-content .hero-btns {
  margin-top: 35px;
}

.hero-area.hero-3-area .hero-content .hero-btns .main-btn {
  border-color: #ff6b58;
  color: #ff6b58;
  background: transparent;
  margin-right: 14px;
}

.hero-area.hero-3-area .hero-content .hero-btns .video-popup {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .hero-btns .video-popup {
    margin-top: 20px;
  }
}

.hero-area.hero-3-area .hero-content .hero-btns .video-popup i {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  margin-right: 10px;
}

.hero-area.hero-3-area .hero-content .thumb {
  margin-bottom: -210px;
}

@media (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .thumb {
    display: none;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .thumb {
    display: block;
    margin-bottom: -210px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-3-area.hero-5-area {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-3-area.hero-5-area {
    padding-top: 160px;
  }
}

@media (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area {
    padding-top: 120px;
    padding-bottom: 0px;
  }
}

.hero-area.hero-3-area.hero-5-area .hero-content .thumb {
  margin-bottom: -110px;
}

.hero-area.hero-3-area.hero-5-area .hero-content .title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-3-area.hero-5-area .hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-3-area.hero-5-area .hero-content .title {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area .hero-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area .hero-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.hero-area.hero-3-area.hero-5-area .hero-content .hero-btns .video-popup i {
  border-color: rgba(255, 255, 255, 0.2);
}

.hero-area.hero-3-area.hero-5-area .hero-content .hero-btns .main-btn {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.hero-area.hero-3-area.hero-5-area .hero-content .hero-btns .main-btn:hover {
  background: #f84a6e;
  color: #fff;
}

.hero-area .hero-shape-1 {
  position: absolute;

  left: 0;
  top: 250px;
  z-index: 10;
  animation: linear 15s animationFramesOne infinite;


}


.hero-area .hero-shape-2 {
  position: absolute;
  left: 80px;
  z-index: 10;
  top: 480px;
  animation: linear 15s animationFramesThree infinite;
}

.hero-area .hero-shape-3 {
  position: absolute;
  bottom: 120px;
  right: 320px;
  z-index: 10;
  animation: linear 25s animationFramesFive infinite;
}

.hero-area .hero-shape-4 {
  position: absolute;
  left: 0;
  z-index: 10;
  top: 250px;
  /* z-index: -1; */
  animation: linear 10s animationFramesFive infinite;
}

.hero-area .hero-shape-5 {
  position: absolute;
  left: 0;
  z-index: 10;
  top: 250px;
  /* z-index: -1; */
  animation: linear 30s animationFramesThree infinite;
}

.hero-area .hero-shape-6 {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 250px;
  /* z-index: -1; */
  animation: linear 20s animationFrames7 infinite;
}

.hero-area .hero-shape-7 {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 250px;
  /* z-index: -1; */
  animation: linear 30s animationFramesEight infinite;
}

.hero-area .hero-shape-8 {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 250px;
  /* z-index: -1; */
  animation: linear 25s animationFramesNine infinite;
}

.hero-area .hero-shape-9 {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 250px;
  /* z-index: -1; */
  animation: linear 15s animationFramesTen infinite;
}

.hero-area.hero-8-area {
  position: relative;
  z-index: 10;
}

.hero-area.hero-8-area .home-8-shape-1 {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;
}

.hero-area.hero-8-area .home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.hero-area.hero-8-area .home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}

.hero-area.hero-8-area .home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}

.hero-area.hero-8-area .hero-content-8 .title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-8-area .hero-content-8 .title {
    font-size: 56px;
    line-height: 66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-8-area .hero-content-8 .title {
    font-size: 42px;
    line-height: 66px;
  }
}

@media (max-width: 767px) {
  .hero-area.hero-8-area .hero-content-8 .title {
    font-size: 32px;
    line-height: 44px;
  }
}

.hero-thumb-6 .thumb {
  position: relative;
  z-index: 10;
}

.hero-thumb-6 .thumb .back-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content {
    margin-bottom: 50px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .hero-content {
    margin-bottom: 50px;
    text-align: center;
  }
}

.hero-content>span {

  color: #2b70fa;
  font-size: 14px;
  font-weight: 500;
}

.hero-content .title {
  font-size: 60px;
  line-height: 70px;
  margin-top: 10px;
  margin-bottom: 22px;

  span {
    font-family: 'Hey comic';
  }

  .alpha {
    background: linear-gradient(227deg, #B900B4 0%, #F50000 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3060ms;
  }

  .beta {
    background: linear-gradient(227deg, #1400C8 0%, #00B9FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3110ms;
  }

  .gama {
    background: linear-gradient(227deg, #F50000 0%, #FFD521 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3160ms;
  }

  .delta {
    background: linear-gradient(to left, #da4453, #89216b);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3210ms;
  }

  .mue {
    background: linear-gradient(227deg, #13cfb8 0%, #462775 70%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    animation: moveToRight 1s ease-in-out;
    animation-delay: 3260ms;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content .title {
    font-size: 48px;
    line-height: 58px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content .title {
    font-size: 48px;
    line-height: 58px;
  }
}

@media (max-width: 767px) {
  .hero-content .title {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.hero-content p {
  font-size: 36px;

  line-height: 28px;
}

@media (max-width: 767px) {
  .hero-content p {
    font-size: 15px;
    line-height: 26px;
  }
}

.hero-content ul {
  margin: 41px 0 0;
  padding: 0;
  list-style-type: none;
}

.hero-content ul li {
  display: inline-block;
  margin-right: 8px;
}



@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content ul li {
    margin-bottom: 0px;
  }
}

.hero-content.hero-content-4 {
  padding-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content.hero-content-4 {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .hero-content.hero-content-4 {
    padding-right: 0;
  }
}

.hero-content.hero-content-4>span {
  color: #801f82;
}

.hero-content.hero-content-4 .title {
  font-size: 70px;
  font-weight: 700;
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content.hero-content-4 .title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content.hero-content-4 .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .hero-content.hero-content-4 .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content.hero-content-4 .title {
    font-size: 40px;
    line-height: 50px;
  }
}

.hero-content.hero-content-4 a.main-btn {
  border: 2px solid #801f82;
  color: #801f82;
  background: #fff;
  line-height: 55px;
  padding: 0 40px;
  font-size: 16px;
  margin-top: 40px;
}

.hero-content.hero-content-4 a.main-btn:hover {
  background: #801f82;
  color: #fff;
}

.hero-content.hero-content-6>span {
  color: #9b2cfa;
}

.hero-content.hero-content-6 .title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content.hero-content-6 .title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content.hero-content-6 .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .hero-content.hero-content-6 .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.hero-content.hero-content-6 ul li a {
  background: #9b2cfa;
  border-color: #9b2cfa;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.hero-content.hero-content-6 ul li a span {
  line-height: 20px;
}

.hero-content.hero-content-6 ul li a span span {
  font-size: 16px;
  font-weight: 700;
  display: block;
  line-height: 20px;
}

.hero-content.hero-content-6 ul li a i {
  font-size: 30px;
}

.hero-content.hero-content-6 ul li a:hover {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}

.hero-content.hero-content-6 ul li a.item-2 {
  background: #fff;
  border-color: #fff;
}

.hero-content.hero-content-6 ul li a.item-2:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
  color: #fff;
}

.hero-content.hero-content-6.hero-content-7 .title {
  color: #fff;
}

.hero-content.hero-content-6.hero-content-7 p {
  color: #fff;
  opacity: 0.5;
}

.hero-content.hero-content-6.hero-content-7 ul li a {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}

.hero-content.hero-content-6.hero-content-7 ul li a:hover {
  background: transparent;
  border-color: #54386a;
  color: #fff;
}

.hero-content.hero-content-6.hero-content-7 ul li a.item-2 {
  background: transparent;
  color: #fff;
  border-color: #54386a;
}

.hero-content.hero-content-6.hero-content-7 ul li a.item-2:hover {
  border-color: #fff;
  background: #fff;
  color: #0e1133;
}

.hero-thumb {
  position: relative;
  z-index: 10;
  text-align: center;
}

.hero-thumb .thumb {
  position: relative;
  // top: 5rem;
  // right: 7rem;
}

.hero-thumb .thumb-2 {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 30px 70px 0px rgba(68, 1, 15, 0.2);
}

// // .hero-thumb::before {
// //   position: absolute;
// //   content: "";
// //   left: 50%;
// //   top: 50%;
// //   height: 500px;
// //   width: 500px;
// //   transform: translate(-50%, -50%);
// //   background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
// // background-size: 1800% 1800%;

// -webkit-animation: rainbow 18s ease infinite;
// -z-animation: rainbow 18s ease infinite;
// -o-animation: rainbow 18s ease infinite;
//   animation: rainbow 18s ease infinite;
//     border-radius: 50%;
//     z-index: -1; }

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}


.hero-area-2 {
  padding-top: 290px;
  padding-bottom: 170px;
  background: #eef1f6;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .hero-area-2 {
    padding-top: 190px;
    padding-bottom: 150px;
  }
}

.hero-area-2 .hero-thumb-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48%;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-area-2 .hero-thumb-3 {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-2 .hero-thumb-3 {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .hero-area-2 .hero-thumb-3 {
    width: 36%;
  }
}

.hero-area-2 .hero-shape-1 {
  position: absolute;
  top: 250px;
  left: 130px;
  animation: linear 20s animationFramesOne infinite;
  z-index: -1;
}

.hero-area-2 .hero-shape-2 {
  position: absolute;
  bottom: 290px;
  left: 40px;
  animation: linear 20s animationFramesFour infinite;
  z-index: -1;
}

.hero-area-2 .hero-shape-3 {
  position: absolute;
  top: 480px;
  right: 240px;
  animation: linear 20s animationFramesFive infinite;
  z-index: -1;
}

.hero-area-2 .hero-shape-4 {
  position: absolute;
  top: 210px;
  right: 0px;
  animation: linear 20s animationFramesThree infinite;
  z-index: -1;
}

.hero-content-2>span {
  font-size: 16px;
  font-weight: 500;
  color: #ff3e66;
  position: relative;
}

.hero-content-2>span::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-content-2 .title {
  font-size: 64px;
  line-height: 70px;
  font-weight: 400;
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content-2 .title {
    font-size: 50px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .hero-content-2 .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-2 .title {
    font-size: 40px;
    line-height: 50px;
  }
}

.hero-content-2 .title span {
  font-weight: 900;
}

.hero-content-2 p {
  font-size: 18px;
  color: #0e1133;
  margin-top: 22px;
}

.hero-content-2 .input-box {
  position: relative;
  margin-top: 40px;
  width: 400px;
}

@media (max-width: 767px) {
  .hero-content-2 .input-box {
    width: 100%;
  }
}

.hero-content-2 .input-box>i {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #c3c3cc;
}

.hero-content-2 .input-box input {
  width: 100%;
  height: 60px;
  border-radius: 40px;
  border: 0;
  border-left: 3px solid #ff3e66;
  padding-left: 55px;
  box-shadow: -3px 0px 0px 0px rgba(255, 62, 102, 0.004),
    0px 30px 30px 0px rgba(14, 17, 51, 0.1);
}

.hero-content-2 .input-box button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 0;
  color: #ff3e66;
}

.hero-content-2 .hero-users {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.hero-content-2 .hero-users>span {
  margin-left: 20px;
  font-size: 26px;
  font-weight: 900;
  color: #0e1133;
  width: 60px;
  display: inline-block;
  line-height: 16px;
}

.hero-content-2 .hero-users>span span {
  font-size: 13px;
  font-weight: 500;
  color: #505056;
  display: inline-block;
  line-height: 13px;
}

.page-title-area {
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.page-title-area.page-service-title-area {
  height: 650px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-title-area.page-service-title-area {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .page-title-area.page-service-title-area {
    height: 400px;
  }
}

.page-title-area.page-service-title-area .page-title-item .thumb {
  right: 110px;
  width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .page-title-area.page-service-title-area .page-title-item .thumb {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-title-area.page-service-title-area .page-title-item .thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .page-title-area.page-service-title-area .page-title-item .thumb {
    display: none;
  }
}

.page-title-item {
  margin-top: 80px;
  position: relative;
}

.page-title-item .thumb {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 160px;
}

@media (max-width: 767px) {
  .page-title-item .thumb {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .page-title-item .thumb {
    display: block;
  }
}

.page-title-item span {
  color: #fff;
  font-weight: 500;
  opacity: 0.7;
  padding-bottom: 14px;
}

.page-title-item .title {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .page-title-item .title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .page-title-item .title {
    font-size: 32px;
  }
}

.page-title-item nav {
  display: inline-block;
  margin-top: 20px;
}

.page-title-item nav ol {
  background: transparent;
  padding: 0;
}

.page-title-item nav ol li a {
  color: #fff;
  opacity: 0.8;
}

.page-title-item nav ol li.active {
  color: #fff;
  opacity: 0.8;
}

/*===========================
    4.APPIE SERVOCE css 
===========================*/
.service-area {
  background: #eef1f6;
  position: relative;
  // background-position: 92% 100%;
  background-repeat: no-repeat;
  background-image: url(../images/tile_background.png);
  // background-image:;
  background-size: auto;
}

.feature-title {
  background-color: #234e70;
  border-radius: 25px;

  @include mobile {
    border-radius: 0px;
    padding-bottom: 0px;
  }
}

.service-area.service-3-area {
  background: none;
}

@media (max-width: 767px) {
  .service-area.service-3-area {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-area.service-3-area {
    padding-top: 190px;
  }
}

.single-service {
  padding: 50px 30px 43px;
  background: #fff;
  background: linear-gradient(130deg, rgba(255, 216, 233, 1) 0%, rgba(225, 250, 255, 1) 100%);

  border-radius: 6px;
  transition: all linear 0.3s;
  box-shadow: 0px 40px 40px 20px rgba(14, 17, 51, 0.1);
  height: 415px;
  cursor: pointer;

  @include mobile {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .single-service {
    padding: 50px 15px 43px;
  }
}

.single-service .icon {
  display: inline-block;
  border-radius: 20px;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background: linear-gradient(135deg, #84bcfa 0%, #2b70fa 100%);
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
  position: relative;
}

.single-service .icon>span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  background: #2b70fa;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 3px 6px 0px rgba(14, 17, 51, 0.1);
  line-height: 28px;
  color: #fff;
}

.single-service .title {
  font-size: 20px;
  font-weight: 700;
  padding-top: 33px;
  padding-bottom: 10px;
  color: #0066cc;
  font-size: 2rem;
  text-decoration: underline;
  font-family: 'Hey comic';

  @include mobile {
    font-size: 1.8rem;
  }
}

.single-service {
  p {
    // color: #fff;
    // font-family: 'Righteous';
    font-weight: 600;
  }
}

.single-service:hover {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  transform: translateY(-15px);
}

.single-service.item-2 .icon {
  background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}

.single-service.item-2 .icon span {
  background: #f8452d;
}

.single-service.item-3 .icon {
  background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}

.single-service.item-3 .icon span {
  background: #31c369;
}

.single-service.item-4 .icon {
  background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}

.single-service.item-4 .icon span {
  background: #9b2cfa;
}

.single-service.single-services-3 {
  background: transparent;
}

.single-service.single-services-3 .icon {
  border-radius: 50%;
  background-image: -moz-linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
  background-image: -webkit-linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
  background-image: -ms-linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
  background-image: linear-gradient(110deg, #ff5f4a 0%, #ff9e91 100%);
}

.single-service.single-services-3 a {
  color: #505056;
  font-size: 14px;
  font-weight: 500;
  margin-top: 25px;
}

.single-service.single-services-3 a:hover {
  color: #ff6b58;
}

.single-service.single-services-3.item-2 {
  background: linear-gradient(0deg,
      rgba(39, 153, 246, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%);
}

.single-service.single-services-3.item-2 .icon {
  background-image: -moz-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
  background-image: -webkit-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
  background-image: -ms-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
  background-image: linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
}

.single-service.single-services-3.item-3 .icon {
  background-image: -moz-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
  background-image: -webkit-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
  background-image: -ms-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
  background-image: linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
}

.single-service.single-services-3.item-4 {
  background: linear-gradient(0deg,
      rgba(119, 89, 237, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%);
}

.single-service.single-services-3.item-4 .icon {
  background-image: -moz-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
  background-image: -webkit-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
  background-image: -ms-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
  background-image: linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
}

.single-service.single-services-3.single-services-5 a:hover {
  color: #801f82;
}

@media (max-width: 767px) {
  .services-2-area .section-title {
    text-align: center !important;
  }
}

.single-service-2 {
  border: 1px solid #eef0f4;
  border-radius: 10px;
  padding: 48px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
  .single-service-2 {
    text-align: center;
    padding: 20px;
  }
}

.single-service-2 .icon i {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #2eacfc;
  color: #fff;
  border-radius: 50%;
}

.single-service-2 .title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}

.single-service-2 a {
  font-size: 14px;
  font-weight: 500;
  color: #505056;
  margin-top: 23px;
}

.single-service-2 a:hover {
  color: #ff3e66;
}

.single-service-2:hover {
  box-shadow: 0px -3px 0px 0px rgba(234, 76, 137, 0.004),
    0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  border-top: 3px solid #2eacfc;
  transform: translateY(-10px);
}

.single-service-2.item-2 .icon i {
  background: #ea4c89;
}

.single-service-2.item-2:hover {
  border-top-color: #ea4c89;
}

.single-service-2.item-3 .icon i {
  background: #3578ff;
}

.single-service-2.item-3:hover {
  border-top-color: #3578ff;
}

.single-service-2.item-4 .icon i {
  background: #f67741;
}

.single-service-2.item-4:hover {
  border-top-color: #f67741;
}

.single-service-2.item-5 .icon i {
  background: #6a88f7;
}

.single-service-2.item-5:hover {
  border-top-color: #6a88f7;
}

.single-service-2.item-6 .icon i {
  background: #3ab094;
}

.single-service-2.item-6:hover {
  border-top-color: #3ab094;
}

.single-service-2.single-service-about {
  border-radius: 0;
  padding: 0 90px 30px 0;
  border: 0;
  transition: all linear 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .single-service-2.single-service-about {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .single-service-2.single-service-about {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.single-service-2.single-service-about .icon i {
  background-color: #f1f7fe;
  color: #2b70fa;
  border-radius: 35% 65% 50% 50% / 59% 50% 50% 41%;
  font-size: 20px;
}

.single-service-2.single-service-about:hover {
  box-shadow: none;
  margin-top: 20px;
}

.single-service-2.single-service-about.item-2 .icon i {
  color: #db0f30;
  background-color: #fef3f5;
  border-radius: 50% 50% 50% 50% / 50% 75% 25% 50%;
}

.single-service-2.single-service-about.item-3 .icon i {
  color: #9b2cfa;
  background-color: #f7eeff;
  border-radius: 25% 75% 50% 50% / 50% 50% 50% 50%;
}

.single-service-2.single-service-about.item-4 .icon i {
  color: #f8452d;
  background-color: #fef0ee;
  border-radius: 40% 60% 40% 60% / 40% 60% 40% 60%;
}

.single-service-2.single-service-about.item-5 .icon i {
  color: #31c369;
  background-color: #f4fcf7;
  border-radius: 33% 67% 50% 50% / 61% 72% 28% 39%;
}

.single-service-2.single-service-about.item-6 .icon i {
  color: #f6ac0e;
  background-color: #fef7e7;
  border-radius: 21% 79% 58% 42% / 50% 46% 54% 50%;
}

.services-6-area .section-title .main-btn:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
}

.services-6-area .single-service-2 a:hover {
  color: #9b2cfa;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .service-details-sidebar {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .service-details-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.service-details-sidebar .service-category-widget {
  border-radius: 6px;
  box-shadow: 0px 30px 50px 0px rgba(14, 17, 51, 0.1);
  padding: 30px;
  margin-bottom: 30px;
}

.service-details-sidebar .service-category-widget ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.service-details-sidebar .service-category-widget ul li {
  margin-bottom: 20px;
}

.service-details-sidebar .service-category-widget ul li i {
  color: #2b70fa;
  width: 15px;
  margin-right: 15px;
}

.service-details-sidebar .service-category-widget ul li:last-child {
  margin-bottom: 0;
}

.service-details-sidebar .service-download-widget {
  border-radius: 6px;
  margin-top: 10px;
  box-shadow: 0px 0px 50px 0px rgba(14, 17, 51, 0.1);
}

.service-details-sidebar .service-download-widget a {
  padding: 13px 30px;
  color: #505056;
}

.service-details-sidebar .service-download-widget a:hover {
  color: #2b70fa;
}

.service-details-sidebar .service-download-widget i {
  color: #2b70fa;
  padding-right: 15px;
}

.service-details-content .thumb {
  border-radius: 10px;
  overflow: hidden;
}

.service-details-content .content {
  padding-top: 34px;
}

.service-details-content .content .title {
  font-size: 30px;
  margin-bottom: 18px;
}

.service-details-content .content span {
  font-size: 20px;
  line-height: 30px;
  color: #0e1133;
  padding-top: 34px;
  padding-bottom: 26px;
}

.services-8-area .service-thumb {
  margin-left: -50px;
  margin-right: -100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services-8-area .service-thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .services-8-area .service-thumb {
    display: none;
  }
}


/*===========================
    10.APPIE PROJECT css 
===========================*/
.project-area .project-box {
  background-image: url(../images/project-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 70px;
  padding-top: 75px;
  padding-bottom: 85px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .project-area .project-box {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-area .project-box {
    padding-left: 70px;
  }
}

.project-area .project-box .project-thumb {
  position: absolute;
  right: 70px;
  bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-area .project-box .project-thumb {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-area .project-box .project-thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .project-area .project-box .project-thumb {
    display: none;
  }
}

.project-content {
  padding-right: 50px;
}

.project-content .title {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .project-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.project-content p {
  color: #fff;
  opacity: 0.8;
}

.project-content .input-box {
  display: flex;
}

@media (max-width: 767px) {
  .project-content .input-box {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-content .input-box {
    display: flex;
  }
}

.project-content .input-box input {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  border-radius: 6px;
  border: 0;
}

.project-content .input-box button {
  margin-left: 10px;
  padding: 0 30px;
  border-radius: 6px;
  padding: 0 25px;
  background: #0e1133;
  border: 0;
  color: #fff;
  line-height: 50px;
}

@media (max-width: 767px) {
  .project-content .input-box button {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-content .input-box button {
    margin-top: 0px;
    margin-left: 10px;
  }
}

.project-3-area.project-5-area {
  background: #eef1f6;
}

.project-3-box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 55px 70px;
  box-shadow: 0px 40px 50px 0px rgba(138, 16, 0, 0.3);
  margin-bottom: -110px;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

@media (max-width: 767px) {
  .project-3-box {
    text-align: center;
  }
}

.project-3-box .title {
  color: #fff;
  font-size: 46px;
  line-height: 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3-box .title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .project-3-box .title {
    font-size: 35px;
    line-height: 46px;
    margin-bottom: 30px;
  }
}

.project-3-box a {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
  line-height: 55px;
  padding: 0 40px;
}

/*===========================
    10.APPIE FOOTER css 
===========================*/
.footer-area {
  background: #eef1f6;
  padding-top: 50px;
  padding-bottom: 60px;
}

.footer-area.footer-3-area {
  padding-top: 180px;
}

.footer-area.footer-3-area.footer-5-area {
  background: #fff;
}

.footer-area.footer-about-area {
  padding-top: 230px;
}

.footer-area.footer-about-area.footer-8-area {
  background: #fff;
}

.footer-area.footer-about-area.footer-8-area .footer-about-widget .social ul li a {
  border: 2px solid #e6e7ea;
}

.footer-area.footer-about-area.footer-8-area .footer-about-widget .social ul li a:hover {
  background: #db0f30;
  border-color: #db0f30;
  color: #fff;
}

.footer-area.footer-about-area.footer-8-area .footer-navigation ul li a:hover {
  color: #db0f30;
}

.footer-area.footer-about-area.footer-8-area .footer-widget-info ul li a i {
  color: #db0f30;
}

.footer-area.footer-about-area.footer-8-area .footer-copyright .apps-download-btn ul li a {
  background: #0e1133;
  color: #fff;
  border-color: #0e1133;
}

.footer-area.footer-about-area.footer-8-area .footer-copyright .apps-download-btn ul li a:hover {
  background: #fff;
  color: #0e1133;
}

.footer-area.footer-about-area.footer-8-area .footer-copyright .apps-download-btn ul li a.item-2 {
  background: #fff;
  color: #0e1133;
}

.footer-area.footer-about-area.footer-8-area .footer-copyright .apps-download-btn ul li a.item-2:hover {
  background: #0e1133;
  color: #fff;
  border-color: #0e1133;
}

.footer-about-widget {
  padding-right: 70px;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .footer-about-widget {
    text-align: center;
    padding-right: 0;
  }
}

.footer-about-widget p {
  padding-top: 25px;
}

.footer-about-widget>a {
  font-size: 15px;
  font-weight: 500;
  color: #0e1133;
  margin-top: 15px;
}

.footer-about-widget>a i {
  padding-left: 6px;
}

.footer-about-widget .social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-about-widget .social ul li {
  display: inline-block;
}

.footer-about-widget .social ul li a {
  height: 36px;
  width: 36px;
  text-align: center;
  background: #fff;
  color: #0e1133;
  border-radius: 6px;
  line-height: 36px;
  margin-right: 5px;
}

.footer-about-widget .social ul li a:hover {
  background: #2b70fa;
  color: #fff;
}

.footer-about-widget.footer-about-widget-2 ul li a:hover {
  background: #ff3e66;
}

.footer-about-widget.footer-about-widget-3 ul li a:hover {
  background: #ff6b58;
}

.footer-about-widget.footer-about-widget-6 ul li a:hover {
  background: #9b2cfa;
}

.footer-about-widget.footer-about-widget-5 ul li a:hover {
  background: #801f82;
}

.footer-navigation {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .footer-navigation {
    text-align: center;
  }
}

.footer-navigation .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}

.footer-navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-navigation ul li a {
  color: #505056;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
}

.footer-navigation ul li a:hover {
  color: #2b70fa;
}

.footer-navigation.footer-navigation-2 ul li a:hover {
  color: #ff3e66;
}

.footer-navigation.footer-navigation-3 ul li a:hover {
  color: #ff6b58;
}

.footer-navigation.footer-navigation-6 ul li a:hover {
  color: #9b2cfa;
}

.footer-navigation.footer-navigation-5 ul li a:hover {
  color: #801f82;
}

.footer-widget-info {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .footer-widget-info {
    text-align: center;
  }
}

.footer-widget-info .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}

.footer-widget-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-widget-info ul li a {
  color: #505056;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.footer-widget-info ul li a i {
  position: absolute;
  left: 0;
  top: 0;
  color: #2b70fa;
}

.footer-copyright {
  padding-top: 35px;
  border-top: 1px solid #e1e5ec;
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright .apps-download-btn {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .footer-copyright .apps-download-btn {
    margin-bottom: 20px;
  }
}

.footer-copyright .apps-download-btn ul {
  padding: 0;
  list-style-type: none;
}

.footer-copyright .apps-download-btn ul li {
  display: inline-block;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .footer-copyright .apps-download-btn ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-copyright .apps-download-btn ul li {
    margin-bottom: 0px;
  }
}

.footer-copyright .apps-download-btn ul li a {
  background: #fff;
  color: #0e1133;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 2px solid #fff;
}

.footer-copyright .apps-download-btn ul li a i {
  padding-right: 6px;
}

.footer-copyright .apps-download-btn ul li a:hover {
  background: transparent;
  color: #0e1133;
  border-color: #e7eaef;
}

.footer-copyright .apps-download-btn ul li a.item-2 {
  background: transparent;
  color: #0e1133;
  border-color: #e7eaef;
}

.footer-copyright .apps-download-btn ul li a.item-2:hover {
  background: #fff;
  color: #0e1133;
}

.error-area {
  padding-top: 250px;
}

.error-content span {
  display: block;
  font-size: 50px;
  color: #db0f30;
  font-weight: 800;
  padding-top: 40px;
}

.error-content .title {
  font-size: 50px;
  font-weight: 800;
  color: #0e1133;
  padding-bottom: 11px;
}

.error-content p {
  line-height: 26px;
  color: #505056;
  font-size: 16px;
  padding: 0 50px;
}

.error-content a {
  border: 2px solid #2b70fa;
  line-height: 45px;
  padding: 0 35px;
  border-radius: 6px;
  margin-top: 30px;
}

.signup-area.signup-8-area {
  background: #eef1f6;
}

.signup-area.signup-8-area .signup-box form .input-box button {
  background: #db0f30;
}

.signup-box {
  padding: 63px 100px 60px;
  border-radius: 10px;
  box-shadow: 0px 40px 50px 0px rgba(0, 24, 72, 0.3);
  overflow: hidden;
  position: relative;
  margin-bottom: -160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup-box {
    padding: 30px 50px;
  }
}

@media (max-width: 767px) {
  .signup-box {
    padding: 30px;
  }
}

.signup-box .thumb {
  position: absolute;
  right: 70px;
  bottom: 30px;
  width: 160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup-box .thumb {
    display: none;
  }
}

@media (max-width: 767px) {
  .signup-box .thumb {
    display: none;
  }
}

.signup-box span {
  color: #fff;
  font-weight: 500;
}

.signup-box .title {
  font-size: 46px;
  color: #fff;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .signup-box .title {
    font-size: 30px;
  }
}

.signup-box form {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup-box form {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .signup-box form {
    flex-direction: column;
  }
}

.signup-box form .input-box {
  margin-right: 10px;
  margin-top: 10px;
}

.signup-box form .input-box input {
  width: 300px;
  background: #fff;
  line-height: 55px;
  border-radius: 6px;
  padding-left: 30px;
  border: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup-box form .input-box input {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .signup-box form .input-box input {
    width: 100%;
  }
}

.signup-box form .input-box button {
  background: #2b70fa;
  line-height: 55px;
  border: 0;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  font-weight: 500;
}

.appie_checkbox_common {
  margin-top: 15px;
}

.appie_checkbox_common label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}

.appie_checkbox_common label a {
  text-decoration: underline;
  color: #b3b6bc;
}

.appie_checkbox_common input[type="checkbox"] {
  display: none;
}

.appie_checkbox_common input[type="checkbox"]+label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
}

.appie_checkbox_common input[type="checkbox"]+label span:before {
  color: #fff;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -1px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.appie_checkbox_common input[type="checkbox"]:checked+label span {
  background: #2079ff;
  border-color: #2079ff;
}

.appie_checkbox_common input[type="checkbox"]:checked+label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.appie_checkbox_common input[type="checkbox"]+label span,
.appie_checkbox_common input[type="checkbox"]:checked+label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.appie_checkbox_common.checkbox_style2 input[type="checkbox"]+label span {
  border-radius: 3px;
}

/*===========================
    11.APPIE ABOUT css 
===========================*/
.about-box {
  background: #f0f1f7;
  padding: 70px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .about-box {
    padding: 30px;
    text-align: center;
  }
}

.about-box .about-thumb {
  position: absolute;
  left: 0;
  top: -65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-box .about-thumb {
    position: static;
  }
}

@media (max-width: 767px) {
  .about-box .about-thumb {
    display: none;
  }
}

.about-content {
  padding-right: 50px;
}

@media (max-width: 767px) {
  .about-content {
    padding-right: 0;
  }
}

.about-content span {
  font-size: 14px;
  font-weight: 500;
  color: #ff3e66;
}

.about-content .title {
  font-size: 44px;
  line-height: 54px;
  margin-top: 8px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  .about-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.about-service {
  position: relative;
  padding-left: 40px;
}

@media (max-width: 767px) {
  .about-service {
    text-align: left;
  }
}

.about-service .icon {
  position: absolute;
  left: 0;
  top: 5px;
}

.about-service .icon i {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  color: #fff;
  background: #31c369;
}

.about-service .title {
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-service .title {
    font-size: 16px;
  }
}

.about-service p {
  font-size: 15px;
  margin-top: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-service p {
    font-size: 14px;
  }
}

.about-3-area .traffic-title {
  padding-right: 100px;
}

@media (max-width: 767px) {
  .about-3-area .traffic-title {
    text-align: center;
    padding-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-3-area .traffic-title {
    text-align: left;
    padding-right: 50px;
  }
}

.about-3-area .traffic-title .title {
  margin-bottom: 15px;
}

.about-3-area .traffic-service {
  padding-left: 0;
  padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-3-area .traffic-service {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .about-3-area .traffic-service {
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-3-area .traffic-service {
    text-align: left;
    padding-right: 50px;
    padding-left: 0;
  }
}

.about-3-area .traffic-service .icon {
  position: static;
  background-image: -moz-linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
  background-image: -webkit-linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
  background-image: -ms-linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
  background-image: linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50%;
  line-height: 50px;
  box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
  margin-bottom: 23px;
}

.about-3-area .traffic-service.item-2 .icon {
  background-image: -moz-linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
  background-image: -webkit-linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
  background-image: -ms-linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
  background-image: linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
}

.about-3-area .traffic-service.item-3 .icon {
  background-image: -moz-linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
  background-image: -webkit-linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
  background-image: -ms-linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
  background-image: linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
}

.about-3-area .traffic-service.item-4 .icon {
  background-image: -moz-linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
  background-image: -webkit-linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
  background-image: -ms-linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
  background-image: linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-thumb-3 {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .about-thumb-3 {
    margin-bottom: 40px;
  }
}

.about-thumb-3 img {
  width: 100%;
}

.about-top-title-area {
  padding-top: 227px;
}

.about-top-title {
  padding-right: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-top-title {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .about-top-title {
    padding-right: 0;
  }
}

.about-top-title .title {
  font-size: 60px;
  position: relative;
  z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-top-title .title {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-top-title .title {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .about-top-title .title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-top-title .title {
    font-size: 40px;
  }
}

.about-top-title .title::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 8px;
  width: 100%;
  height: 20px;
  background: #ff6e85;
  z-index: -1;
}

.about-top-title p {
  font-size: 20px;
  color: #0e1133;
  padding-top: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-top-title p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-top-title p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .about-top-title p {
    font-size: 16px;
  }
}

.about-page-area {
  padding-top: 462px;
  padding-bottom: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 345px;
}

@media (max-width: 767px) {
  .about-page-area {
    margin-top: 80px;
    padding-top: 80px;
  }
}

.about-page-content {
  position: relative;
}

.about-page-content .title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.about-page-content p {
  color: #fff;
  padding-top: 15px;
  opacity: 0.7;
}

.about-page-content a {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  line-height: 40px;
  padding: 0 30px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 43px;
}

.about-page-content a:hover {
  background: #fff;
  color: #2b70fa;
}

.about-page-content::before {
  position: absolute;
  content: "";
  left: 0;
  top: -710px;
  height: 645px;
  width: 80vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-page-content::before {
    width: 94vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-page-content::before {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .about-page-content::before {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-page-content::before {
    width: 100%;
    height: 400px;
    display: block;
  }
}

.how-it-work-content>.title {
  font-size: 44px;
}

.how-it-work-content p {
  font-size: 18px;
  padding-top: 9px;
}

.how-it-work-content .how-it-work-box {
  position: relative;
  padding-left: 70px;
  margin-top: 45px;
}

.how-it-work-content .how-it-work-box span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background: #eef4ff;
  color: #2b70fa;
  font-size: 22px;
  font-weight: 500;
}

.how-it-work-content .how-it-work-box .title {
  font-size: 18px;
  font-weight: 500;
}

.how-it-work-content .how-it-work-box:hover span {
  background: #2b70fa;
  box-shadow: 0px 14px 20px 0px rgba(0, 43, 130, 0.3);
  color: #fff;
}

.about-8-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.about-8-box {
  padding: 50px 50px 145px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-8-box {
    padding: 50px 25px 145px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-8-box {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .about-8-box {
    margin-bottom: 30px;
    padding: 50px 20px 145px;
  }
}

.about-8-box .thumb {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.about-8-box .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 14px;
}

@media (max-width: 767px) {
  .about-8-box .title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-8-box .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.about-8-box a {
  border: 2px solid #db0f30;
  color: #db0f30;
  background: #fff;
  margin-top: 30px;
}

.about-8-box a:hover {
  color: #fff;
  background: #db0f30;
  border-color: #db0f30;
}